import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { useRegistration } from '../../hooks/useRegistration'
import ClipLoader from 'react-spinners/ClipLoader'
import Arrow from '../../images/link-arrow.svg'
import ArrowRight from '../../images/icons/chevron-right.svg'


export default function RegisterForm({setIsCompleted}) {

  const { register, error, status } = useRegistration();
  const [user, setUser] = useState({ email: '', password: ''});

  useEffect(() => {
    status === 'resolved' && setIsCompleted(true)
  }, [status])

  const submit = (e) => {
    e.preventDefault();
    console.log('register 1');
    register(user.email, user.password);
  }

  return (
    <div className="register-form">
      <Link to="/" className="hover-underline">
        <img src={Arrow} alt="arrow" />
        Föregående
      </Link>
      <div>
        <h2>Skapa ett kostnadsfritt konto</h2>
        <form onSubmit={submit}>
          <div className="form-row">
            <label htmlFor="email">E-postadress</label>
            <input onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} type="email" name="email" id="email" autoComplete="off" disabled={status === 'resolving'} required/>
          </div>
          <div className="form-row">
            <label htmlFor="password">Lösenord</label>
            <input onChange={(e) => setUser({ ...user, password: e.target.value })} value={user.password} type="password" name="password" id="password" autoComplete="off" disabled={status === 'resolving'} required/>
          </div>
          <p>Välj sedan att starta en gratisperiod utan att ange några kortuppgifter eller köp programmet direkt.</p>
          <button type="submit" className="arrow" disabled={status === 'resolving'}>
            Skapa konto
            <img src={ArrowRight} alt="arrow" />
          </button>
        
          <div className="messages">
            {status === 'resolving' &&
              <div className="text-center spinner">
                <ClipLoader size={22} color={"#ffffffbb"} />
              </div>
            }
            {error && (
              <div className="error-notice text-center">
                <p>{error}</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
