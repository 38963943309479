import React, {useState} from 'react'
import RegisterForm from '../../components/forms/RegisterForm'
import Layout from '../../components/Layout'
import Envelope from '../../images/envelope.svg'
import Helmet from 'react-helmet'
import CookieBar from "../../components/CookieBar";

export default function Register() {

  const [isCompleted, setIsCompleted] = useState(false)
  
  return (
    <Layout>
      <Helmet>
        <title>Pausit | Skapa konto</title>
      </Helmet>
      {!isCompleted
        ? <RegisterForm setIsCompleted={(value) => setIsCompleted(value)}/>
        : <div className="account-created">
            <div className="circle">
              <img src={Envelope} alt="envelope" />
            </div>
            <h2>Kolla din mail</h2>
            <p>Ditt konto är skapat!</p>
            <p>Vi har skickat en länk till dig. Klicka på länken för att verifiera dig och aktivera ditt konto. Kom ihåg att kolla i skräpposten.</p>
          </div>
      }
      {/* <CookieBar /> */}
    </Layout>
  )
}

