/**
 * External dependencies
 */
import { useState } from 'react';
import { decodeEntities } from '@wordpress/html-entities';
import { stripHtml } from 'string-strip-html';

/**
 * Internal dependencies
 */
import { useRegisterMutation } from './mutations/useRegisterMutation';
import { useAppState } from '../components/context';
import { useLoginMutation } from './mutations/useLoginMutation';
import { navigate } from 'gatsby';

const errorCodes = {
  
  // // invalid_username:
  // //   'Invalid username or email address. Please check it and try again.',
  // invalid_email: 'Invalid email address. Please check it and try again.',
  // incorrect_password:
  //   'Incorrect password. Please try again, or reset your password.',
  // // empty_username: 'Please provide your username.',
  // empty_password: 'Please provide your password.',
};

/**
 * Hook which registers a new user.
 */
export const useRegistration = () => {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('idle');
  const { registerMutation } = useRegisterMutation();
  // const { setIsLoggedIn } = useAppState();

  // const { loginMutation } = useLoginMutation();

  const register = (email, password) => {
    setError(null);
    setStatus('resolving');
    return registerMutation(email, password)
      .then(() => {
        // setIsLoggedIn(true);
        setStatus('resolved');
        console.log('registerMutation klar');
        

        // loginMutation(email, password)
        // .then(() => {
        //   console.log('loggar in');
        //   setIsLoggedIn(true);
        //   navigate("/shop");
        // })
        // .catch((errors) => {
        //   console.log('loginError' + errors);
        // })

      })
      .catch((errors) => {
        console.log(errors);
        console.log(errors.message);
        console.log((stripHtml(decodeEntities(errors.message))).result)
        switch (stripHtml(decodeEntities(errors.message)).result) {
          case 'An account is already registered with your email address. Please log in.':
            setError('Ett konto har redan registrerats med den här e-postaddressen.')
            break;
          default:
            break;
        }
        errors.message === '' && setError('Ett konto är redan registrerat med din e-post adress. Logga in')
        setError(
          errorCodes[errors.message] ||
          `${stripHtml(decodeEntities(errors.message)).result
          }`
        );
        setStatus('');
      });
  };

  return {
    register,
    error,
    status,
  };
};
