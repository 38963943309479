import { gql, useMutation } from '@apollo/client';

const REGISTER = gql`
	mutation RegisterCustomer(
		$email: String!
		$password: String!
	) {
		registerCustomer(
			input: { email: $email, password: $password }
		) {
			clientMutationId
		}
	}
`;

export const useRegisterMutation = () => {
  const [mutation, mutationResults] = useMutation(REGISTER);

  const registerMutation = (email, password) => {
    return mutation({
      variables: {
        email,
        password,
      },
    });
  };

  return { registerMutation, results: mutationResults };
};
